import React from "react";

import  GoogleLogo from "../illustrations/btn_google_signin_dark_normal_web@2x.png";

const authProviders = [
  {
    id: "google.com",
    color: "",
    icon: <img src={GoogleLogo} height="50" alt='Google Logo' ></img>,
    name: "",
  },
];

export default authProviders;
