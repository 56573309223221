import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography'; {children}
import {Box, Grid} from '@material-ui/core';

import WarehouseAddrCard from "../WarehouseAddrCard";


function TabPanel(props) {

    const { children, value, index,...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
    user : PropTypes.any,
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));



export default function SimpleTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid className={classes.grid} container justify="center" spacing={5}>
        <Grid item xs={12}>
            <div className={classes.root} >
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="shipping dashboard">
                <Tab label="Your warehouse Address" {...a11yProps(0)} />
                <Tab label="Packages at the warehouse" {...a11yProps(1)} />
                <Tab label="Packages on the way" {...a11yProps(2)} />
                <Tab label="Completed" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} user={props}>
                <Box>
                    <WarehouseAddrCard user={props.user}/>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <h2>You have no packages at our warehouse</h2>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <h2>You have no packages on the way</h2>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <h2>You have no completed deliveries</h2>
            </TabPanel>
            </div>
        </Grid>
        </Grid>
    );
}
