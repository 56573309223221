import React, { Component } from "react";

import EmptyState from "../EmptyState";

import { Card, CardHeader, CardContent } from "@material-ui/core";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";


const initialState = {
  performingAction: false,
  firstName: "",
  lastName: "",
  streetAddress: "",
  emailAddressConfirmation: "",
  zip: "",
  country: "",
  errors: null,
};

class QuoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleFirstNameChange = (event) => {
    const firstName = event.target.value;

    this.setState({
      firstName: firstName,
    });
  };

  handleLastNameChange = (event) => {
    const lastName = event.target.value;

    this.setState({
      lastName: lastName,
    });
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  handleEmailAddressConfirmationChange = (event) => {
    const emailAddressConfirmation = event.target.value;

    this.setState({
      emailAddressConfirmation: emailAddressConfirmation,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  handlePasswordConfirmationChange = (event) => {
    const passwordConfirmation = event.target.value;

    this.setState({
      passwordConfirmation: passwordConfirmation,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    const {
      performingAction,
      emailAddress,
      emailAddressConfirmation,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      errors,
    } = this.state;

    return (
      <Card>
        <CardHeader>
          <Typography variant="h1" >
            Fill out the form to get your shipping quote
          </Typography>
        </CardHeader>

        <Hidden xsDown>
          <CardContent>
            <Grid container direction="row"
                  justify="center"
                  alignItems="center">

              <Grid item xs={3} >
                <Grid container direction="column" spacing={2}>

                <Grid item xs>
                    <TextField
                      label="First Name"
                      disabled={performingAction}
                      required
                      type="text"
                      error={!!(errors && errors.firstName)}
                      value={firstName}
                      helperText={
                        errors && errors.firstName ? errors.firstName[0] : ""
                      }
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handleFirstNameChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      label="Last Name"
                      disabled={performingAction}
                      required
                      type="text"
                      error={!!(errors && errors.lastName)}
                      value={lastName}
                      helperText={
                        errors && errors.lastName ? errors.lastName[0] : ""
                      }
                      variant="outlined"
                      InputLabelProps={{ required: false}}
                      onChange={this.handleLastNameChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="email"
                      disabled={performingAction}
                      error={!!(errors && errors.emailAddress)}
                      fullWidth
                      helperText={
                        errors && errors.emailAddress
                          ? errors.emailAddress[0]
                          : ""
                      }
                      label="E-mail address"
                      placeholder="john@doe.com"
                      required
                      type="email"
                      value={emailAddress}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handleEmailAddressChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="email"
                      disabled={performingAction}
                      error={!!(errors && errors.emailAddressConfirmation)}
                      fullWidth
                      helperText={
                        errors && errors.emailAddressConfirmation
                          ? errors.emailAddressConfirmation[0]
                          : ""
                      }
                      label="E-mail address confirmation"
                      placeholder="john@doe.com"
                      required
                      type="email"
                      value={emailAddressConfirmation}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handleEmailAddressConfirmationChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="new-password"
                      disabled={performingAction}
                      error={!!(errors && errors.password)}
                      fullWidth
                      helperText={
                        errors && errors.password ? errors.password[0] : ""
                      }
                      label="Password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      type="password"
                      value={password}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handlePasswordChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="password"
                      disabled={performingAction}
                      error={!!(errors && errors.passwordConfirmation)}
                      fullWidth
                      helperText={
                        errors && errors.passwordConfirmation
                          ? errors.passwordConfirmation[0]
                          : ""
                      }
                      label="Password confirmation"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      type="password"
                      value={passwordConfirmation}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handlePasswordConfirmationChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Hidden>

        <Hidden smUp>
          <CardContent>

            <Grid container direction="column" spacing={2}>

            <Grid item xs>
              <TextField
                label="First poop"
                disabled={performingAction}
                required
                type="text"
                error={!!(errors && errors.firstName)}
                value={firstName}
                helperText={
                  errors && errors.firstName ? errors.firstName[0] : ""
                }
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handleFirstNameChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  label="Last Name"
                  disabled={performingAction}
                  required
                  type="text"
                  error={!!(errors && errors.lastName)}
                  value={lastName}
                  helperText={
                    errors && errors.lastName ? errors.lastName[0] : ""
                  }
                  variant="outlined"
                  InputLabelProps={{ required: false}}
                  onChange={this.handleLastNameChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  autoComplete="email"
                  disabled={performingAction}
                  error={!!(errors && errors.emailAddress)}
                  fullWidth
                  helperText={
                    errors && errors.emailAddress ? errors.emailAddress[0] : ""
                  }
                  label="E-mail address"
                  placeholder="john@doe.com"
                  required
                  type="email"
                  value={emailAddress}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handleEmailAddressChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  autoComplete="email"
                  disabled={performingAction}
                  error={!!(errors && errors.emailAddressConfirmation)}
                  fullWidth
                  helperText={
                    errors && errors.emailAddressConfirmation
                      ? errors.emailAddressConfirmation[0]
                      : ""
                  }
                  label="E-mail address confirmation"
                  placeholder="john@doe.com"
                  required
                  type="email"
                  value={emailAddressConfirmation}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handleEmailAddressConfirmationChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  autoComplete="new-password"
                  disabled={performingAction}
                  error={!!(errors && errors.password)}
                  fullWidth
                  helperText={
                    errors && errors.password ? errors.password[0] : ""
                  }
                  label="Password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  type="password"
                  value={password}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handlePasswordChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  autoComplete="password"
                  disabled={performingAction}
                  error={!!(errors && errors.passwordConfirmation)}
                  fullWidth
                  helperText={
                    errors && errors.passwordConfirmation
                      ? errors.passwordConfirmation[0]
                      : ""
                  }
                  label="Password confirmation"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  type="password"
                  value={passwordConfirmation}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handlePasswordConfirmationChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Hidden>

        <DialogActions>
          <Button
            color="primary"
            disabled={
              !firstName||
              !lastName ||
              !emailAddress ||
              !emailAddressConfirmation ||
              !password ||
              !passwordConfirmation ||
              performingAction
            }
            variant="contained"
            onClick={this.signUp}
          >
            Sign up
          </Button>
        </DialogActions>
      </Card>
    );

  }


}

export default QuoteForm;
