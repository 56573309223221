import React from "react";

import PropTypes from "prop-types";

import { Card, CardHeader, CardContent } from "@material-ui/core";

import Typography from '@material-ui/core/Typography';

function WarehouseAddrCard(props) {
  const user = props.user;

  return (
    <Card>
      <CardHeader
        title={`${user.firstName ? 'Hi ' + user.firstName + ', here is your warehouse address'  : 'Complete your profile under settings in top right.'  } `}
        CardContent={`Locker ${user.lockerNumber}`}
      />
      <CardContent>
        <Typography>
        {`Full name: ${ user.firstName ? user.firstName : 'Complete profile under settings'} ${user.lastName ? user.lastName : ''}`}
        </Typography>
        <Typography>
        {`Address line 1:  17 Penny Grove`}
        </Typography>
        <Typography>
        {`Address line 2: Suite ${user.lockerNumber}`}
        </Typography>
        <Typography>
        {`City: Taupo`}
        </Typography>
        <Typography>
        {`Region: Waikato`}
        </Typography>
        <Typography>
        {`ZIP/Postcode: 3330`}
        </Typography>
        <Typography>
        {`Country: New Zealand`}
        </Typography>
        <Typography>
        {`Phone: 09 886 9520`}
        </Typography>
      </CardContent>
    </Card>
  );
}

WarehouseAddrCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default WarehouseAddrCard;
